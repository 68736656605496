.client_primary_color{
    color: #d2282b !important;
}

.client_secondary_color{
    color: #fff !important;
}

.client_primary_bg-color{
    background-color: #d2282b !important;
}

.client_secondary_bg-color{
    background-color: #fff !important;
}

.client_primary_bg{
    background: #d2282b !important;
}

.client_secondary_bg{
    background: #fff !important;
}

.client_primary_border-color{
    border: 1px solid #d2282b;
}

.client_secondary_border-color{
    border: 1px solid #fff;
}

.element_center{
    justify-content: center;
    align-items: center;
    display: flex;
}